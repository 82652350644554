.guide{
    background: #EBEBEB;
    border-bottom: 4px solid #108EE9;
    padding:10px 10px 10px 30px;
}

.intro{
   margin:10px 30px -10px 30px !important;
   font-weight: bold;
}
.intro_1{
    margin:10px 30px 0px 30px !important;
    font-weight: bold;
 }

.cgs{
    display: block;
    font-size: 40px;
    color:#B5B5B5;
    width:560px;
    margin: 0 auto; 
    padding:50px 0px 50px 0;
}

