.headblue{
    width: 100%;
    height: 90px;
    background-color: #108EE9;
    display: inline-flex;
    
  }
  
  .header{
    height: 64px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 64px;
    background: #FFF;
    display: flex;
    margin-bottom: 50px;
  }
  
  .headerMenu{
    margin: 0 auto;
  }
  .headMenu1{
    font-size:20px;
    width:200px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding:0 60px;
  }
  
  .headMenu2{
    font-size:20px;
    width:200px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding:0 60px;
    
  }
  .logo-warp{
    margin-left:320px;
    width:500px;
    margin-top: -130px;
  }
  .help{
    display: block;
    font-size:25px;
    color:white;
    height: 40px;
    margin-top:25px;
    margin-left: 0px;
  }
  .dot{
     font-size: 80px;
     color:white;
     margin-top:-25px;
     margin-left: -170px;
     margin-right: 10px;
  }
  .sider-guide{
    box-sizing: border-box;
    width:200px;
    height: 40px;
    background-color: #108EE9;
    color:white;
    font-size:20px ;
    margin-bottom: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 40px;
  }
  
  .ant-layout-has-sider{
  
   padding:0px !important;

   background:rgb(240,242,245) !important;
   
  }
  .ant-layout-content{
    width: 1300px;
    padding:0px 0px 20px 0px !important;
  }
  .ant-layout-sider{
    margin-right: 24px;
  }

  .sellMenuItem{
    margin:2px 0px 4px 0px !important;
  }
  