.guide{
    background: #EBEBEB;
    border-bottom: 4px solid #108EE9;
    padding:10px 10px 10px 30px;
}

.intro{
   margin:20px 30px -10px 30px;
   font-weight: bold;
}
.title{
   display: inline;
  
   font-weight: bold;
}
.desc{
    display: inline;
    color: #B5B5B5;
}
.titleAndDesc{
    padding-left:30px;
}