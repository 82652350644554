.guide{
    background: #EBEBEB;
    border-bottom: 4px solid #108EE9;
    padding:10px 10px 10px 30px;
    font-weight: bold;
}

.intro{
   margin:10px 30px -10px 30px;
   font-weight: bold;
}

.intro_1{
   margin:10px 30px 10px 30px;
   font-weight: bold;
}