.guide{
    background: #EBEBEB;
    border-bottom: 4px solid #108EE9;
    padding:10px 10px 10px 30px;
}

.intro{
   margin:20px 30px;
   font-weight: bold;
}

.cgs{
    display: block;
    font-size: 40px;
    color:#B5B5B5;
    width:560px;
    margin: 0 auto; 
}
.pic-warp{
    width: 522px;
    margin:0 auto;
}
.point{
    padding:30px;
    font-weight: bold;
}